import React from "react"
import Layout from "../components/layout"

const ContactPage = () => (
  <Layout>
    <div>
      <h2>Contact Informaiton Here</h2>
    </div>
  </Layout>
)

export default ContactPage
